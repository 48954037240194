import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import IndexHeader from "../components/homepage/indexHeader"
import Container from "../components/container"
import Footer from "../components/footer"
import Img from "gatsby-image"

const StyledIndexPage = styled.div`
    .bigTitle {
        padding: 5px 0;

        display: inline-block;
        margin-top: 20px;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }

    .flexBox {
        margin-top: 1.2em;
    }

    .blogImage {
        border-radius: 5px;
        margin-bottom: 1em;
    }
    .excerpt {
        width: 100%;
    }
    .excerptText {
        height: 5em;
        overflow: hidden;
    }

    /* réglages reservés aux grands écrans */
    @media only screen and (min-width: 800px) {
        .blogImage {
            margin-right: 1em;
            flex: 0 0 229px;
        }

        .flexBox {
            margin-top: 1.2em;
            display: flex;
        }

        .blogImage {
            margin-bottom: 0em;
        }
    }
`

export default function IndexPage({ data }) {
  const tagColorsDictionary = {
    "Web Dev": "#013e5a",
    Travel: "#333",
    Lifestyle: "#333",
    Random: "#43807a",
  }

  const articles = data.allContentfulBlogPost.nodes.map(function (node, i) {
    const tagStyle = { backgroundColor: tagColorsDictionary[node.tag] }

    return (
      <div key={i}>
        <h2 style={{ display: "inline" }}>
          <Link to={node.slug} style={{ color: "rgb(40, 42, 54)" }}>
            {node.title}
          </Link>
        </h2>{" "}
        - <span className="credit">{node.createdAt}</span>
        {node.tag ? (
          <span className="tag" style={tagStyle}>
            {node.tag}
          </span>
        ) : null}
        <div className="flexBox">
          <Img
            className="blogImage"
            width="229px"
            fluid={node.image.fluid}
            alt="article"
          />
          <div className="excerpt">
            <p className="excerptText">
              {node.excerpt?.excerpt}
              {/* {node.mainContent.content[0].content[0].value} */}
            </p>
            <Link to={node.slug}>Continue Reading</Link>
          </div>
        </div>
        <hr />
      </div>
    )
  })

  return (
    <StyledIndexPage>
      <Container title="antoineweber.com">
        <IndexHeader />
        <hr />
        {articles}
        <Footer />
      </Container>
    </StyledIndexPage>
  )
}

export const query = graphql`
    {
        allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
            nodes {
                excerpt {
                    excerpt
                }
                title
                tag
                image {
                    fluid(maxWidth: 335, maxHeight: 192, quality: 100, resizingBehavior: FILL) {
                        ...GatsbyContentfulFluid_noBase64
                    }
                }
                slug
                createdAt(formatString: "MMMM D, YYYY")
            }
        }
    }
`
