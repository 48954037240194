import React from "react"
import Logo from "../logo.js"
import Icon from "../icon"

import { faTwitter, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"

export default function IndexHeader() {
  const planeEmoji = (
    <span role="img" aria-label="plane take-off">
      🛫
    </span>
  )

  return (
    <div>
      {/*Blog Logo*/}
      <Logo />

      {/*Blog Title*/}
      <h1 style={{ letterSpacing: "1px", marginTop: "0.67em" }}>@antoineweber {planeEmoji}</h1>
      <p>My island on the web ocean - Ice and sea pictures are cool, see below.</p>

      {/* Social medias icons */}
      <a href="https://twitter.com/antoineweber">
        <Icon name={faTwitter} />
      </a>
      <a href="https://www.github.com/slk333">
        <Icon name={faGithub} />
      </a>
      <a href="https://www.linkedin.com/in/antoine-weber-ab555535/">
        <Icon name={faLinkedin} />
      </a>
    </div>
  )
}
