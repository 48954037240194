import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const IconContainer = styled.div`
  display: inline-block;
  height: 16px;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  color: #73737d;
`;
export default function Icon(props) {
  return (
    <IconContainer>
      <FontAwesomeIcon icon={props.name} />
    </IconContainer>
  );
}
